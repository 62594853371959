// Import the _mixins.scss file
@import '../../Sass/utils/mixins';
@import '../../Sass/utils/variables';


.navbar {
  background: $primaryColor;
  width: 60px;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  &__logo {
    padding: 10px 5px;
  }

  &__group {
    @include flexColCenter;

    &__link{
      font-size: 28px;
      color: $backgroundColor;
      display: block;
      line-height: 51px;
      height: 51px;
  
      &:hover {
        color: $secondaryColor;
      }

      &.active {
        svg {
          color: $secondaryColor;
        }
      }
    }
  }

  &--mobile {
    padding: 25px 15px;
    background-color: $primaryColor;
    height: 100vh;
    width: 180px;

    &__icon {
      position : fixed !important;
      top: 25px;
      left: 25px;
      color: $primaryColor !important;
      z-index: 5;
    }

    &__list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 20px;
    }

    &__link {
      color: $backgroundColor;
    }

    &__link:hover, 
    &__link.active {
      color: $secondaryColor;
    }
  }
}




// .navbar {
//     background: $primaryColor;
//     width: 60px;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     z-index: 3;
//     min-height: 500px;

//     &--mobile{
//       display: none;

//       @media screen and (max-width: 768px) {
//         display: block;
//         margin-left: 25px;
//       }

//       &__icon {
//         margin-top: 25px !important;
//         margin-left: 25px !important;
//         color: $primaryColor !important;
//         z-index: 5;
//       }

//       &__container {
//         padding-top: 25px;
//         padding-bottom: 25px;
//         background-color: $primaryColor;
//         color: white;
//         height: 100vh;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//       }

//       &__list {
//         padding-left: 15px !important;
//         width: 150px;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         row-gap: 20px;
//       }
//     }
  
//     .logo {
//       img {
//         width: 100%;
//         height: auto;
//         padding: 10px 5px;
//       }
//     }
  
//     nav {
//       display: block;
//       text-align: center;
//       position: absolute;
//       height: 210px;
//       top: 50%;
//       margin-top: -120px;
//       width: 100%;
  
//       a {
//         font-size: 22px;
//         color: $backgroundColor;
//         display: block;
//         line-height: 51px;
//         height: 51px;
//         position: relative;
//         text-decoration: none;
  
//         &:hover {
//           color: $secondaryColor;
//         }
//       }
  
//       a.active {
//         svg {
//           color: $secondaryColor;
//         }
//       }
//     }
  
//     ul {
//       position: absolute;
//       bottom: 20px;
//       width: 100%;
//       display: block;
//       padding: 0;
//       list-style: none;
//       text-align: center;
//       margin: 0;
  
//       li {
//         a {
//           padding: 7px 0;
//           display: block;
//           font-size: 2rem;
//           line-height: 16px;

//           &.resume-link {
//             svg{
//               opacity: 1;
//             }
//             &:hover {
//               svg {
//                 opacity: 0;
//               }
//               &::after {
//                 content: 'CV';
//                 color: $secondaryColor;
//                 letter-spacing: 2px;
//                 display: block;
//                 width: 100%;
//                 text-align: center;
//                 -webkit-transition: all 0.3s ease-out;
//                 transition: all 0.3s ease-out;
//               }
//             }
//           }
  
//           &:hover svg {
//             color: $secondaryColor;
//           }
//         }
//       }
//     }
//   }
  