.flat-button {
    display: inline-block;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 18px;
    white-space: nowrap;
    border: 1px solid $primaryColor;
    border-radius: 4px;
    font-family: $primaryFont;
    font-size: 1rem;
    color: $secondaryColor;
    background-color: rgba(white, 0.45);

    // Active state
    &.active {
      background-color: $secondaryColor;
      color: $backgroundColor;
    }

    // Hover state
    &:not(:disabled):hover {
      background-color: $secondaryColor;
      color: $backgroundColor;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
    }

    // Filter active state
    &.filter-active{
      background-color: $secondaryColor;
      color: $backgroundColor;
    }

    // Back right button for Projects page
    &.back-right {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    // Back left button for Projects page
    &.back-left {
      float: right;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
}