.animate {
    & .certifications__cards,
    & .projects__cards,
    & .stack__element img,
    & .contact__section__form-animation  {
      animation: slideUp 1.5s ease-in-out;
    }
  }

// Apparition of the stacks list for the About page
@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0%;
    }
    to {
        transform: translateY(0%);
        opacity:100%;
    }
}

// Apparition of the projects list for the Projects page
@keyframes apparition-short {
    from {
        transform: translateY(200px);
        opacity: 0%;
    }
    to {
        transform: translateY(0);
        opacity:100%;
    }
}
