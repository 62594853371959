* {
    box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
    font-family: 'Coolvetica';

    &:hover {
      cursor: pointer;
    }
}

p {
    font-size: 1rem;
    color: $secondaryColor;
    font-family: 'Coolvetica';
    letter-spacing: 1px;
    font-weight: 300;
    max-width: -moz-fit-content;
    max-width: fit-content;
    text-align: justify;
    white-space: pre-wrap;
}

h1 {
    font-size: 3.125rem;
    font-family: "Coolvetica";
    color: $primaryColor;
    font-weight: 400;
    margin-top: 10px;
    position: relative;
    margin-bottom: 40px;
    width: fit-content;
    text-align: start;
    white-space: pre-wrap;

    &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: $secondaryColor;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '</h1>';
        font-family: 'La Belle Aurore', cursive;
        color: $secondaryColor;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }

      svg {
        width: auto;
        height: 45px;
        margin-left: 20px;
      }

      @media screen and (max-width: 768px) {
        font-size: 40px;
      }
}

h2 {
    font-family: 'Coolvetica';
    letter-spacing: 1px;
    color: $primaryColor;
    font-size: 2.5rem;
    font-weight: 400;
    position: relative;
    text-transform: capitalize;
    margin:0;

    &:before {
        content: '<h2>';
        font-family: 'La Belle Aurore', cursive;
        color: $secondaryColor;
        font-size: 14px;
        position: absolute;
        left: -10px;
        top: -15px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h2/>';
        font-family: 'La Belle Aurore', cursive;
        color: $secondaryColor;
        font-size: 14px;
        line-height: 14px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }

    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
}

h3 {
    font-size: 2rem;
    margin:0;
    font-family: 'Coolvetica';
    letter-spacing: 1px;
    color: $primaryColor;

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
}

.mr0 {
    margin-right: 0;
    margin-left: auto;
}

input,
textarea {
  font-family: 'Helvetica Neue', sans-serif !important;
  color: $primaryColor !important;
}

figure {
  margin: 0;
  padding: 0;

  figcaption {
    font-size: 1rem;
    color: $secondaryColor;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.accent-color {
    color: $primaryColor;
    font-weight: bold;
}

img {
    max-width: 100%;
    height: auto;
}

.framed-picture {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
}

ul {
  font-family: "Coolvetica";
  font-weight: 300;
  letter-spacing: 1px;
  color: $secondaryColor;
  padding-left: 1.5rem;
  text-align: justify;
}