.page {
    width: calc(100% - 60px);
    right: 0;
    height: auto;
    position: absolute;
    padding-bottom: 100px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.container {
    width: 100%;
    padding: 0 2rem;
    height: auto;
}

.inside-container {
    width: 100%;
    min-height: 100vh;
    max-width: 1200px;
    opacity: 0;
    margin: 0 auto;
    transform-style: preserve-3d;
    animation: fadeIn 1.5s forwards;
    animation-delay: 0.5s;
    will-change: contents;
}